import { Controller } from "@hotwired/stimulus"
import { GoogleCharts } from 'google-charts'
// Connects to data-controller="weekly-reports-diagram"
export default class extends Controller {
  static values = {
    page: String
  }

  async connect() {
    await fetch('/api/v1/projects_colors?' + new URLSearchParams({
      page: this.pageValue,
    })).then((response) => response.json())
        .then((json) => this.collection_of_projects_colors = json );

    await fetch('/api/v1/weekly_diagrams?' + new URLSearchParams({
      page: this.pageValue,
    })).then((response) => response.json())
        .then((json) => this.chart_data = json );

    console.log(this.collection_of_projects_colors)
    GoogleCharts.load(this.drawChart.bind(this))
  }

  drawChart() {
    console.log(this.chart_data[0].length)
    let columnForEightHourLine = this.chart_data[0].length - 2
    let coloredColumns = {
      [`${columnForEightHourLine}`]: {
        type: 'line',
        color: "#000"
      }
    }

    for(let i=0 ; i < this.collection_of_projects_colors.length ; ++i){
      coloredColumns[i] = this.collection_of_projects_colors[i]
    }
    console.log(coloredColumns);

    const data = GoogleCharts.api.visualization.arrayToDataTable(this.chart_data);
    var chart = new GoogleCharts.api.visualization.ComboChart(document.querySelector('#chart_div'));
    chart.draw(data, {
      height: "700",
      width: "100vw",
      isStacked: true,
      legend: {
        position: 'top'
      },
      seriesType: 'bars',
      interpolateNulls: true,
      series: coloredColumns,
      hAxis: {
        format: 'Q#',
        viewWindow: {
          min: 0.5
        }
      },
      chartArea: {
        left: '10%',
        width: '100%'
      }
    });
  }
}
