import { Controller } from "@hotwired/stimulus"
import {get} from "@rails/request.js";

// Connects to data-controller="order-select"
export default class extends Controller {
    static targets = ["selectElement"]

    async updateLineItems(event) {
        const orderId = event.target.value;
        const lineItemSelect = this.selectElementTarget;

        fetch(`/admin/talent_orders/line_items?order_id=${orderId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
            }
        })
            .then(response => response.json())
            .then(lineItems => {
                lineItemSelect.innerHTML = "";

                const defaultOption = document.createElement("option");
                defaultOption.value = "";
                defaultOption.text = "Select Line Item";
                lineItemSelect.add(defaultOption);

                lineItems.forEach(lineItem => {
                    const option = document.createElement("option");
                    option.value = lineItem.id;
                    option.text = lineItem.talent_firstname + " " + lineItem.talent_lastname + " " + new Date(lineItem.start_date).toLocaleDateString();
                    lineItemSelect.add(option);
                });
            })
            .catch(error => {
                console.error('Error fetching tasks:', error);
            });
    }
}