document.addEventListener('turbo:load', () => {
    setInterval(() => {
        if (document.querySelector("#generate-csv")) {
            let csvButton = document.querySelector("#generate-csv")
            let pdfButton = document.querySelector("#generate-pdf")


            csvButton.addEventListener("click", () => {
                csvButton.disabled =  true
                setTimeout(() => {
                    csvButton.disabled =  false
                }, 2000)
            })

            pdfButton.addEventListener("click", () => {
                pdfButton.disabled =  true
                setTimeout(() => {
                    pdfButton.disabled =  false
                }, 10000)
            })
        }
    }, 2000)
})
