import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js";

// Connects to data-controller="start-stop"
export default class extends Controller {
    static targets = [ "duration", "description", "project", "timeEntryId" ]

    connect() {
    }

    async updateAttributes(){
        const params = {
            project: this.projectTarget.value,
            description: this.descriptionTarget.value,
            duration: this.durationTarget.value,
        };
        this.urlPing = `start_stop_time_entries/update_attributes/${this.timeEntryIdTarget.value}`;
        const response = await patch(this.urlPing, {
            body: params,
            contentType: 'application/json',
            responseKind: 'turbo-stream'
        });
        if (response.ok) {
            console.log('all good', response);
        }
    }

    formatDuration(){

    }

    unformatDuration(){

    }
}
