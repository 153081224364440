import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

// Connects to data-controller="talent-selection"
export default class extends Controller {
    static targets = [ "dateRange", "timeGroup", "groupBy"]
    connect() {
    }

    async switchBetween(event){

        let d = event.target
        if(d.tagName === "svg"){
            d = d.parentElement
        }

        if(d.getAttribute("grayed") === null){
            d.style.textDecoration = "line-through"
            d.style.backgroundColor = "gray"
            d.setAttribute("grayed", true)
        }
        else{
            d.style.textDecoration = "unset"
            d.style.backgroundColor = d.getAttribute("background-color-attribute")
            d.removeAttribute("grayed")
        }

        let talents_badges = document.querySelectorAll("[talent-badge][grayed]")
        let all_talents = document.querySelectorAll("[talent-badge]")

        let talent_ids  = Array.prototype.map.call(talents_badges, (badge => badge.id.replace("t", "")));
        let talent_colors = Array.prototype.map.call(talents_badges, (badge => badge.getAttribute("background-color-attribute")));

        const params = { q: {
            range_date: this.dateRangeTarget.value,
            group_by: this.groupByTarget.value,
            time_group: this.timeGroupTarget.value,
            talents: talent_ids,
            talent_colors: talent_colors,
            element_initial_amount: all_talents.length
        } };
        console.log(params)
        this.urlPing = `${window.location.pathname}/grouping`;
        const response = await post(this.urlPing, {
            body: params,
            contentType: 'application/json',
            responseKind: 'turbo-stream'
        });
    }
}
