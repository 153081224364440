import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notifications"
export default class extends Controller {
  static targets = [ "frame" ]

  connect(){
    setTimeout(() => {
      this.frameTarget.classList.add('transition');
      this.frameTarget.classList.add('ease-in');
      this.frameTarget.classList.add('duration-100');
      this.frameTarget.classList.add('opacity-0');
    }, 3000);
  }

  close() {
    this.frameTarget.classList.add('transition');
    this.frameTarget.classList.add('ease-in');
    this.frameTarget.classList.add('duration-100');
    this.frameTarget.classList.add('opacity-0');
  }
}
