import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

// Connects to data-controller="change-description-hints-by-project"
export default class extends Controller {
    static targets = [ "selector" ];

    connect(){}

    async redraw() {
        const params = { project: this.selectorTarget.value };
        this.urlPing = `${window.location.pathname}/update_hinting`;
        const response = await post(this.urlPing, {
            body: params,
            contentType: 'application/json',
            responseKind: 'turbo-stream'
        });
        if (response.ok) {
            console.log('all good', response);
        }
    }
}
