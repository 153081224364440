import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reload-select"
export default class extends Controller {
  connect() {
  }

  change(event) {
    const frame = document.getElementById('switch_time_entries');

    let frame_url = new URL(frame.src);
    let params = new URLSearchParams(frame_url.search);

    params.set('selected', event.target.value);
    frame.src = `${frame_url.origin}${frame_url.pathname}?${params}`
  }
}
