
export function recalculate(input_value){
    if(input_value.includes(':')) {
        const valuesSplitted = input_value.split(":")
        let hours = parseInt(valuesSplitted[0]);
        let minutes = parseInt(valuesSplitted[1]);

        if(isNaN(hours))
            hours = 0
        if(isNaN(minutes))
            minutes = 0

        minutes = checkIfGoodFormat(minutes, 0, 59);
        hours = checkIfGoodFormat(hours, 0, 23);

        hours = oneDecimalIntoTwo(hours);
        minutes = oneDecimalIntoTwo(minutes);
        return `${hours}:${minutes}`;
    }
    let hours = parseInt(input_value);

    if(isNaN(hours))
        return '00:00';

    if(hours > 9999)
      return '23:59';

    if(input_value.length <= 2) {
        let realHours = checkIfGoodFormat(hours, 0, 23);

        realHours = oneDecimalIntoTwo(realHours);
        return `${realHours}:00`;
    }

    const hoursInString = input_value;

    let hoursForInput = hoursInString.slice(0, 2)

    let realHours = checkIfGoodFormat(hoursForInput, 0, 23);

    let minutesForInput = hoursInString.slice(2, 4)
    let realMinutes = checkIfGoodFormat(minutesForInput, 0, 59);
    realHours = oneDecimalIntoTwo(realHours);
    realMinutes = oneDecimalIntoTwo(realMinutes);

    return `${realHours}:${realMinutes}`;

}

export function formToDecimal(input_value) {
    return input_value.replace(':', '');
}


function oneDecimalIntoTwo(number){
    if(number.toString().length === 1)
        return `0${number}`;

    return number;
}

function checkIfGoodFormat(number, min, max){
    if(isNaN(number))
        return 0;

    if(number > max)
        return max;

    if(number < min)
        return min;

    return number;
}
