let adjustFormFields = (form) => {
  if (form) {
    let talentSelect = form.querySelector(".talent-select-js")
    let talentPositionField = form.querySelector(".talent-position-js")
    let projectManagerFieldsWrappers = form.querySelectorAll(".project-manager-fields-wrapper-js")
    let projectManagerInputs = form.querySelectorAll(".project-manager-input-js")
    let regularTalentFieldsWrappers = form.querySelectorAll(".regular-talent-fields-wrapper-js")
    let regularTalentInputs = form.querySelectorAll(".regular-talent-input-js")
    let fteFieldInput = form.querySelector(".fte-field-js")

    adjustFields = () => {
      if (talentSelect.tagName == "SELECT") {
        talentIsProjectManager = talentSelect.options[talentSelect.selectedIndex].getAttribute("data-is-project-manager")
      } else {
        talentIsProjectManager = talentSelect.getAttribute("data-is-project-manager")
      }

      if (talentIsProjectManager == "true") {
        talentPositionField.readOnly = true
        talentPositionField.value = "Project Manager"

        projectManagerFieldsWrappers.forEach((field) => {
          field.style.display = "flex"
        })
        regularTalentFieldsWrappers.forEach((field) => {
          field.style.display = "none"
        })

        regularTalentInputs.forEach((input) => {
          input.value = ""
          input.required=""
        })

        projectManagerInputs.forEach((input) => {
          input.value = ""
          input.required="required"
        })

        fteFieldInput.value = "100"
      } else {
        talentPositionField.readOnly = false
        if (!form.classList.contains("order-form-js")) {
          talentPositionField.value = ""
          fteFieldInput.value = ""
        }

        projectManagerFieldsWrappers.forEach((field) => {
          field.style.display = "none"
          field.required=""
        })
        regularTalentFieldsWrappers.forEach((field) => {
          field.style.display = "flex"
        })

        regularTalentInputs.forEach((input) => {
          input.value = ""
          input.required="required"
        })

        projectManagerInputs.forEach((input) => {
          input.value = ""
          input.required=""
        })
      }
    }

    adjustFields()

    talentSelect.addEventListener("change", adjustFields)
  }
}

document.addEventListener('turbo:load', () => {
  let addLineItemForm = document.querySelector(".add-line-item-form-js")

  if (addLineItemForm) {
    adjustFormFields(addLineItemForm)
  }

  let submitOnChangeSelects = document.querySelectorAll(".submit-on-change-js")
  if (submitOnChangeSelects) {
    submitOnChangeSelects.forEach((select) => {
      select.addEventListener('change', () => {
        document.querySelector(".submit-on-change-form-js").submit();
      });
    })
  }
})

// TODO: find better way to share JS across files
window.adjustFormFields = adjustFormFields
