import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="report-select-buttons"
export default class extends Controller {
  static targets = [ "multipleSelect" ]

  connect() {
  }

  all(event){
    let allData = this.multipleSelectTarget.slim.getData();
    let newData = allData.map(this.getValues);
    this.multipleSelectTarget.slim.setSelected(newData);
  }

  nothing(event){
    this.multipleSelectTarget.slim.setSelected([]);
  }

  getValues(item){
    return item.value;
  }

}
