import { Controller } from "@hotwired/stimulus";
import { easepick } from '@easepick/bundle';
import { RangePlugin } from '@easepick/range-plugin';
import { PresetPlugin } from '@easepick/preset-plugin';

// Connects to data-controller="calendar-range-input"
export default class extends Controller {
    static targets = [ "dateInput" ];

    connect() {
        const picker = new easepick.create({
            element: this.dateInputTarget,
            css: [
                'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
            ],
            zIndex: 100,
            plugins: [RangePlugin, PresetPlugin],
            format: "DD/MM/YYYY",
        });
    }
}
