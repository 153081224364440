import { Controller } from "@hotwired/stimulus"
import {formToDecimal, recalculate} from '../time_entries_forms'
// Connects to data-controller="weekly-hours-formatting"
export default class extends Controller {
  connect() {

  }

  toDecimal(input_value) {
    let valueFromInputWithoutColon = formToDecimal(input_value.target.value)
    input_value.target.value = valueFromInputWithoutColon
  }

  change(event) {
    event.target.value = recalculate(event.target.value);
  }
}
