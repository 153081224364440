import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="order-talent-filter"
export default class extends Controller {
    static targets = ["selectElement"]

    async updateTalents(event) {
        const projectId = event.target.value;
        const talentsSelect = this.selectElementTarget;

        fetch(`/admin/projects/talents?project_id=${projectId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
            }
        })
            .then(response => response.json())
            .then(talents => {
                talentsSelect.innerHTML = "";

                const defaultOption = document.createElement("option");
                defaultOption.value = "";
                defaultOption.text = "Select Talents";
                talentsSelect.add(defaultOption);

                talents.forEach(talent => {
                    const option = document.createElement("option");
                    option.value = talent.id;
                    option.text = talent.firstname + " " + talent.lastname;
                    talentsSelect.add(option);
                });
            })
            .catch(error => {
                console.error('Error fetching talents:', error);
            });
    }
}