import { Controller } from "@hotwired/stimulus"
import {post} from "@rails/request.js";

// Connects to data-controller="reload-view"
export default class extends Controller {
    async connect() {
        console.log("haha huhu")
        this.urlPing = `start_stop_time_entries/refresh_time_entries`;
        const response = await post(this.urlPing, {
            contentType: 'application/json',
            responseKind: 'turbo-stream'
        });
        if (response.ok) {
            console.log('all good', response);
        }
    }
}
