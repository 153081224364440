import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="report-select-buttons-all-updated"
export default class extends Controller {
  static targets = [ "multipleSelect", "divChosen" ]

  connect() {
    console.log(this.divChosenTarget)
  }

  all(event){
    this.divChosenTarget.style.visibility = "visible";
    this.multipleSelectTarget.slim.setSelected([]);
  }

  nothing(event){
    this.divChosenTarget.style.visibility = "hidden";
    this.multipleSelectTarget.slim.setSelected([]);
  }
}
