import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select';


// Connects to data-controller="search-select"
export default class extends Controller {
  static targets = [ "selectElement" ];

  connect() {
    new SlimSelect({
      select: this.selectElementTarget
    });
  }
}
