import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-date-form"
export default class extends Controller {
  static targets = [ "dates" ]

  connect() {
  }

  showOrHideDates(event){
    console.log(event);
    if(event.target.value === 'custom') {
      this.datesTarget.style.visibility = 'visible';
    }
    else {
      this.datesTarget.style.visibility = 'hidden';
    }
  }

}
