import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

// Connects to data-controller="grouping-time-entries"
export default class extends Controller {
  static targets = [ "transaction" ];
  static values = {
    query: Object
  }

  connect(){
  }

  async redraw() {
    let hashWithQueryForChart = this.transactionTarget.getAttribute('data-grouping-time-entries-query-value');
    const params = { group_by: this.transactionTarget.value, q: hashWithQueryForChart };
    this.urlPing = `${window.location.pathname}/grouping`;
    const response = await post(this.urlPing, {
      body: params,
      contentType: 'application/json',
      responseKind: 'turbo-stream'
    });
    if (response.ok) {
      console.log('all good', response);
    }
  }

  get transaction() {
    return this.transactionTarget.value;
  }
}
