// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "@hotwired/turbo-rails"
import "channels"
import "../controllers"
import "flowbite"
import "../../../node_modules/flowbite/dist/datepicker"
import "chartkick/chart.js"

Rails.start()
ActiveStorage.start()
Turbo.session.drive = false

require("flash_messages")
require("flowbite_reconnect")
require("form_submit")
require("client_form")
require("order_form")
require("talent_order_form")
require("get_link")
require("block_buttons_after_generate")
require("gantt_board")
require("start_end_date")
// TODO: below script causes some problems- maybe will be fixes and used later
// require("google_docs_parser")
