document.addEventListener('turbo:load', () => {
  if (document.querySelector(".edit-client-form-js")) {
    let companyDetails = document.querySelector(".client-details-js").value

    checkCompanyDataChange = () => {
      let updatedCompanyDetails = document.querySelector(".client-details-js").value

      if (updatedCompanyDetails != companyDetails) {
        return confirm("If you change Company Data you probably should Add New Client instead. Are you sure you want save changes?")
      } else {
        return true
      }
    }

  }
})
