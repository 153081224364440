import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="multiple-time-entries-checkboxes"
export default class extends Controller {
    static targets = ["primary", "output"]

    connect() {
    }

    checkAll(e) {
        if (e.target.classList.contains("entries_main_checkbox")) {
            let targets = this.outputTargets;
            let properTargets = targets.filter(checkbox => checkbox.classList.contains(e.target.name));
            properTargets.forEach(checkbox => {
                checkbox.checked = e.target.checked;
            });
        } else {
            let targets = this.primaryTargets;
            let properTargets = targets.filter(checkbox => e.target.classList.contains(checkbox.name));

            if (properTargets.length === 1) {
                let properDateName = properTargets[0].name;

                let outputTargets = this.outputTargets;
                let properOutputTargets = outputTargets.filter(checkbox => checkbox.classList.contains(properDateName));
                let allChecked = true;

                properOutputTargets.forEach(checkbox => {
                    if (!checkbox.checked) {
                        allChecked = false;
                    }
                });
                properTargets[0].checked = allChecked;
            }
        }
    }

    deleteSelected() {
        let targetsToDelete = this.outputTargets;
        let properTargetsToDelete = targetsToDelete.filter(checkbox => checkbox.checked);
        let ids = properTargetsToDelete.map(checkbox => parseInt(checkbox.value));

        if (ids.length > 0) {
            if (!confirm("Do you really want to delete " + ids.length + " logs?")) {
                return;
            }
            fetch('/talent/time_entries/delete_checked', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
                },
                body: JSON.stringify({log_ids: ids})
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                       location.reload();
                    }
                });
        }
    }
}