import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

// Connects to data-controller="counting-time"
export default class extends Controller {
    static targets = ["duration"];

    connect() {
        this.counting()
    }

    holdCounting(){
        clearInterval(this.interval);
    }

    continueCounting(){
        this.counting()
    }

    singleToDouble(number){
        if(number.toString().length === 1)
            return `0${number}`
        return number
    }

    counting(){
        this.interval = setInterval((e) => {
            let time_splitted = this.durationTarget.value.split(":")
            let seconds = parseInt(time_splitted[0]) * 3600 +
                parseInt(time_splitted[1]) * 60 +
                parseInt(time_splitted[2]) + 1

            let hours = Math.floor(seconds / 3600)
            let minutes = Math.floor((seconds - hours * 3600) / 60)
            seconds = Math.floor(seconds - hours * 3600 - minutes * 60)
            this.durationTarget.value =
                `${this.singleToDouble(hours)}:${this.singleToDouble(minutes)}:${this.singleToDouble(seconds)}`
        }, 1000)
    }
}

