document.addEventListener('turbo:load', () => {
  setInterval(() => {
    if (document.querySelector(".flash-message-js")) {
      let messages = document.querySelectorAll(".flash-message-js")

      setTimeout(() => {
        messages.forEach((message) => {
          message.style.display = "none"
        })
      }, 3000)

      messages.forEach((message) => {
        message.addEventListener("mouseover", () => {
          message.style.display = "none"
        })
      })

    }
  }, 2000)
})
