import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="task-description"
export default class extends Controller {
  static targets = ["detailrow", "image"]

  connect() {
  }

  dropdown(event) {
    const allTargets = this.detailrowTargets;

    const images = this.imageTargets;

    const row = event.target.closest(".task-row-main");
    const index = row.getAttribute("task-row-index");
    const caretImg = row.getElementsByClassName("img-caret-up")[0];

    if (caretImg.classList.contains("caret-off")) {
      caretImg.src = images[1].src;
      caretImg.classList.remove("caret-off");
    } else {
      caretImg.src = images[0].src;
      caretImg.classList.add("caret-off");
    }

    allTargets.map(target => {
      if (target.getAttribute("task-row-index") === index) {
        if (target.getAttribute("hidden")) {
          target.removeAttribute("hidden");
        } else {
          target.setAttribute("hidden", "hidden");
        }
      }
    });

  }
}
