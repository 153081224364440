import Holidays from "date-holidays";

class GanttChart {
    static TASK_HEIGHT = 40;
    static TASK_GAP = 15;
    static START_DATE = new Date(new Date().getFullYear(), new Date().getMonth()-1, 1);
    static END_DATE = new Date(new Date().getFullYear(), new Date().getMonth()+2, 0);
    static CONTAINER_PERCENT_TALENT = 0.2;
    static START_SORTING = "name";

    constructor() {
        this.hd = new Holidays("PL");
    }

    setData(data) {
        this.tasks = data;
        this.ganttChartContainer = document.getElementById('ganttboard__main');
    }

    render(filterData = { start_date: GanttChart.START_DATE, end_date: GanttChart.END_DATE, statuses: ["approved", "pending", "not signed", "draft"], talents: [], projects: [], sources: [], ftes: [], sort: null, order_grouping: null }) {
        console.log(filterData.projects)

        if (filterData.start_date === undefined || filterData.end_date === undefined) {
            filterData.start_date = this.start_date;
            filterData.end_date = this.end_date;
        }
        filterData.statuses = filterData.statuses ?? this.statuses;
        filterData.projects = filterData.projects ?? this.projects;
        filterData.talents = filterData.talents ?? this.talents;
        filterData.sources = filterData.sources ?? this.sources;
        filterData.ftes = filterData.ftes ?? this.ftes;

        if (filterData.sort === this.sort) {
            this.sort_asc = !this.sort_asc;
        } else if (filterData.sort) {
            this.sort_asc = false;
            this.sort = filterData.sort;
        }

        const taskData = this.#filterTasks(filterData);

        let allProjects = new Set();
        taskData.forEach(talent => {
            talent.line_items.forEach(li => {
                allProjects.add(li.project_name);
            });
        });
        allProjects = [...allProjects].sort();

        document.getElementById("projects_counter").innerHTML = "Projects: " + allProjects.length;
        let talentsWithTasks = [...taskData].filter(talent => talent.line_items.length > 0);
        document.getElementById("freelance_counter").innerHTML = "Freelance: " + talentsWithTasks.filter(talent => talent.talent_provider.name === "Freelancer").length;
        document.getElementById("provider_counter").innerHTML = "Provider: " + talentsWithTasks.filter(talent => talent.talent_provider.name !== "Freelancer").length;

        this.ganttChartContainer.innerHTML = "";
        this.taskContainerWidth = this.ganttChartContainer.getBoundingClientRect().width * (1-GanttChart.CONTAINER_PERCENT_TALENT);
        this.talentContainerWidth = this.ganttChartContainer.getBoundingClientRect().width * GanttChart.CONTAINER_PERCENT_TALENT;
        this.DAY_WIDTH = this.taskContainerWidth / Math.floor(Math.abs(this.end_date - this.start_date) / (1000 * 60 * 60 * 24));

        this.#renderLines(filterData.order_grouping ?? this.order_grouping);

        let index = 0;
        allProjects.forEach((searchedProject) => {
            taskData.forEach((talent) => {
                let indexRow = 0;
                const tasks = talent.line_items.filter(li => li.project_name === searchedProject);
                tasks.forEach((task) => indexRow += this.#createTask(task, index, indexRow));

                if (tasks.length > 0) {
                    this.#renderTalent(talent, index);
                    index++;
                }
            });
        });
        const minHeight = (GanttChart.TASK_HEIGHT + GanttChart.TASK_GAP) * index;
        this.ganttChartContainer.style.minHeight = (minHeight > 600 ? minHeight : 600).toString() + "px";
    }

    #renderTalent(talentData, index) {
        const talentContainer = document.createElement('div');
        const top = (GanttChart.TASK_HEIGHT + GanttChart.TASK_GAP) * index;
        const taskHeight = GanttChart.TASK_HEIGHT;
        const taskContainerWidth = this.talentContainerWidth;

        talentContainer.classList.add('ganttboard__talent-container');
        talentContainer.classList.add(`talent-${index}`);
        talentContainer.style.top = top.toString() + 'px';
        talentContainer.style.height = taskHeight + 'px';
        talentContainer.style.width = taskContainerWidth + 'px';
        talentContainer.innerHTML = talentData.talent.firstname + " " + talentData.talent.lastname;

        talentContainer.onclick = function (event) {
            const ganttChartContainer = event.target.parentElement;

            const id = `talent_dropdown-${index}`;
            const talentClass = 'talent_dropdown';

            // when talent already has a dropdown then close it
            const alreadyExisting = Array.from(document.getElementsByClassName(talentClass));
            if (alreadyExisting.length > 0) {
                const talents = document.getElementsByClassName('ganttboard__talent-container_clicked');
                Array.from(talents).forEach(talent => talent.classList.remove('ganttboard__talent-container_clicked'));
                alreadyExisting.forEach(talent => talent.remove());
            }

            // when talent is clicked from another row, close the task dropdown and unclick the task
            const taskDropdown = Array.from(document.getElementsByClassName("task_dropdown"));
            if (taskDropdown.filter(dropdown => !dropdown.classList.contains(`task_dropdown-${index}`)).length > 0) {
                taskDropdown.forEach(dropdown => dropdown.remove());
                const task = Array.from(document.getElementsByClassName("ganttboard__task_clicked"));
                task.forEach(task => task.classList.remove("ganttboard__task_clicked"));
            }

            // if the talent clicked is not the same, then create a new dropdown
            const sameTalentClicked = alreadyExisting.filter(talent => talent.id === `talent_dropdown-${index}`).length === 1;
            if (!sameTalentClicked) {
                event.target.classList.add('ganttboard__talent-container_clicked');
                const talentDropdown = document.createElement('div');

                talentDropdown.id = id;
                talentDropdown.classList.add(talentClass);
                talentDropdown.classList.add('ganttboard__talent-dropdown');
                talentDropdown.style.top = (top + taskHeight + 5).toString() + 'px';
                talentDropdown.style.width = taskContainerWidth + 'px';

                const projectsObj = talentData.projects.map(project => `<div>${project}</div>`);
                talentDropdown.innerHTML = `
                    <div class="ganttboard__tile-segment">Project${projectsObj.join("")}</div>
                    <div class="ganttboard__tile-segment">Source<div>${talentData.talent_provider?.name}</div></div>
                    <div class="ganttboard__tile-segment">Primary skill<div>${talentData.talent?.skill}</div></div>
                    <div class="ganttboard__tile-segment">Seniority<div>${talentData.talent?.seniority}</div></div>
                `;

                ganttChartContainer.appendChild(talentDropdown);``
            }
        };

        this.ganttChartContainer.appendChild(talentContainer);
    }

    #renderLines(orderGrouping) {
        let firstDays = [];
        let currentDate = new Date(this.start_date.getFullYear(), this.start_date.getMonth()+1, 1);
        let endDate = this.end_date;

        orderGrouping = orderGrouping ?? "month";

        switch (orderGrouping) {
            case "month":
                while(currentDate <= endDate) {
                    firstDays.push(new Date(currentDate));
                    currentDate.setMonth(currentDate.getMonth()+1);
                }
            break;
            case "week":
                let firstDayOfWeek = new Date(this.start_date.getFullYear(), this.start_date.getMonth(), 1);
                firstDayOfWeek.setDate(firstDayOfWeek.getDate() - firstDayOfWeek.getDay() + 1);
                if (firstDayOfWeek < this.start_date) {
                    firstDayOfWeek.setDate(firstDayOfWeek.getDate() + 7);
                }
                while(firstDayOfWeek <= endDate) {
                    firstDays.push(new Date(firstDayOfWeek));
                    firstDayOfWeek.setDate(firstDayOfWeek.getDate() + 7);
                }
            break;
        }
        firstDays.push(new Date(this.start_date));
        if (new Date() > new Date(this.start_date) && new Date() < new Date(this.end_date)) {
            const line = document.createElement('div');
            line.classList.add('ganttboard__line');
            line.classList.add('ganttboard__line-today');
            line.style.left = this.#calculatePosition(new Date()) + 'px';

            this.ganttChartContainer.appendChild(line);
        }

        firstDays.forEach(day => {
            const line = document.createElement('div');
            line.classList.add('ganttboard__line');
            line.style.left = this.#calculatePosition(day) + 'px';

            if (!(day.getMonth() === new Date().getMonth() && day.getDate() === new Date().getDate())) {
                this.ganttChartContainer.appendChild(line);
            }

            const label = document.createElement('div');
            label.classList.add('ganttboard__line-label');

            switch (orderGrouping) {
                case "month":
                    const fillZeroMonth = (day.getMonth()+1) < 10 ? "0" : "";
                    label.innerHTML = fillZeroMonth + (day.getMonth() + 1) + "/" + day.getFullYear().toString().substring(2);
                    const nextMonth = new Date(day).setMonth(day.getMonth()+1);
                    const dayDiff = (nextMonth - day) * this.DAY_WIDTH / (1000 * 60 * 60 * 24);
                    label.style.left = this.#calculatePosition(day) + (dayDiff / 2 - 25) + 'px';
                    this.ganttChartContainer.appendChild(label);
                break;
                case "week":
                    if (day.getTime() === this.start_date.getTime()) break;

                    const fillZeroWeek = (weekNumber) < 10 ? "0" : "";
                    const firstDayOfYear = new Date(day.getFullYear(), 0, 1);
                    const dayOfYear = (day - firstDayOfYear + 86400000) / 86400000;
                    const weekNumber = Math.ceil(dayOfYear / 7);
                    label.innerHTML = fillZeroWeek + weekNumber;
                    label.style.left = this.#calculatePosition(day) - 8 + 'px';

                    this.ganttChartContainer.appendChild(label);
                break;
            }
        });
    }

    #createTask(taskData, index, id) {
        const topPosition = (GanttChart.TASK_HEIGHT + GanttChart.TASK_GAP) * index;
        const talentContainerWidth = this.talentContainerWidth;
        const taskContainerWidth = this.taskContainerWidth;
        const taskId = id;

        const taskDates = this.#getTaskArray(taskData.start_date, taskData.end_date);
        let indexRow = 0;

        taskDates.forEach((dates, secIndex) => {
            let startDate = dates.start_date.getTime() < this.start_date ? this.start_date : dates.start_date;
            let endDate = dates.end_date.getTime() >= this.end_date ? this.end_date-1 : dates.end_date;

            const leftPosition = this.#calculatePosition(startDate);
            if (leftPosition > this.talentContainerWidth + this.taskContainerWidth) {
                return;
            }

            const duration = this.#calculateDuration(startDate, endDate);
            if (duration <= 0) {
                return;
            }

            const taskElement = document.createElement('div');
            const taskHeight = GanttChart.TASK_HEIGHT;
            const holidays = this.hd;

            taskElement.classList.add('ganttboard__task');
            taskElement.classList.add(`task-${index}`);
            taskElement.id = `task-${index}-${taskId}-${secIndex}`;
            taskElement.innerHTML = taskData.project_name;
            taskElement.style.left = leftPosition + 'px';
            taskElement.style.top = topPosition.toString() + 'px';
            taskElement.style.height = taskHeight + 'px';
            taskElement.style.width = duration + 'px';

            let colorClass = this.#getTaskColor(taskData.status);
            taskElement.classList.add('ganttboard__task_' + colorClass);

            taskElement.onclick = function (event) {
                const ganttChartContainer = event.target.parentElement;
                const id = `task_dropdown-${index}-${taskId}-${secIndex}`;
                const dropdownClass = "task_dropdown";

                // when task already has a dropdown then close it
                const alreadyExisting = Array.from(document.getElementsByClassName(dropdownClass));
                if (alreadyExisting.length > 0) {
                    alreadyExisting.forEach(task => task.remove());
                    const taskElements = document.getElementsByClassName("ganttboard__task_clicked");
                    Array.from(taskElements).forEach(task => task.classList.remove('ganttboard__task_clicked'));

                    // remove blur for all tasks
                    const blurElements = document.getElementsByClassName('ganttboard__task_blur');
                    Array.from(blurElements).forEach(task => task.classList.remove('ganttboard__task_blur'));
                }

                // when task is clicked from another row, close the talent dropdown and unclick the talent
                const talentDropdown = Array.from(document.getElementsByClassName("talent_dropdown"));
                if (talentDropdown.filter(dropdown => dropdown.id !== `talent_dropdown-${index}`).length > 0) {
                    talentDropdown.forEach(dropdown => dropdown.remove());
                    const task = Array.from(document.getElementsByClassName("ganttboard__talent-container_clicked"));
                    task.forEach(task => task.classList.remove("ganttboard__talent-container_clicked"));
                }

                // if the task clicked is not the same, then create a new dropdown
                const sameTaskClicked = alreadyExisting.filter(task => task.id === id).length > 0;
                if (!sameTaskClicked) {
                    // add blur for other tasks
                    let allTasks = document.getElementsByClassName("ganttboard__task");
                    Array.from(allTasks).filter(task => task.id !== taskElement.id).forEach(task => task.classList.add('ganttboard__task_blur'));

                    taskElement.classList.add('ganttboard__task_clicked');

                    const taskDropdown = document.createElement('div');
                    taskDropdown.classList.add('ganttboard__task-dropdown');
                    taskDropdown.classList.add('ganttboard__task_' + colorClass);
                    taskDropdown.classList.add('task_dropdown-' + index);
                    taskDropdown.classList.add(dropdownClass);
                    taskDropdown.id = id;
                    taskDropdown.style.left = talentContainerWidth + 'px';
                    taskDropdown.style.top = (topPosition + taskHeight + 5).toString() + 'px';
                    taskDropdown.style.width = taskContainerWidth + 'px';

                    // calculate working days in current month
                    const dateDiffDays = (new Date(dates.end_date).setHours(24) - new Date(dates.start_date)) / (24 * 60 * 60 * 1000);
                    let taskWorkingDays = 0;
                    for (let i = 0; i < dateDiffDays; i++) {
                        let createdDate = new Date(dates.start_date.valueOf() + (24 * 60 * 60 * 1000 * i));
                        if (createdDate.getDay() === 0 || createdDate.getDay() === 6) continue;
                        taskWorkingDays += holidays.isHoliday(createdDate) === false ? 1 : 0;
                    }

                    // calculate working days in task
                    const allDateDiffDays = (new Date(taskData.end_date).setHours(24) - new Date(taskData.start_date)) / (24 * 60 * 60 * 1000);
                    let allWorkingDays = 0;
                    let startDate = new Date(taskData.start_date);
                    for (let i = 0; i < allDateDiffDays; i++) {
                        let createdDate = new Date(startDate.valueOf() + (24 * 60 * 60 * 1000 * i));
                        if (createdDate.getDay() === 0 || createdDate.getDay() === 6) continue;
                        allWorkingDays += holidays.isHoliday(createdDate) === false ? 1 : 0;
                    }

                    const partialHours = taskWorkingDays / allWorkingDays;

                    const currentViewing = document.createElement('div');
                    currentViewing.classList.add('ganttboard__task-dropdown-tile');
                    currentViewing.innerHTML = `
                      <div class="ganttboard__tile-title">Current viewing</div>
                      <div class="ganttboard__tile-dates">${new Date(dates.start_date).toLocaleDateString('en-GB') + " - " + new Date(dates.end_date).toLocaleDateString('en-GB')}</div>
                      <div class="ganttboard__tile-hours">${Number(taskData.man_hours * partialHours).toFixed(0) } h</div>
                    `;

                    const totalOrder = document.createElement('div');
                    totalOrder.classList.add('ganttboard__task-dropdown-tile');
                    totalOrder.innerHTML = `
                      <div class="ganttboard__tile-title">Total order</div>
                      <div class="ganttboard__tile-dates">${new Date(taskData.start_date).toLocaleDateString('en-GB') + " - " + new Date(taskData.end_date).toLocaleDateString('en-GB')}</div>
                      <div class="ganttboard__tile-hours"><div>${Number(taskData.man_hours).toFixed(0)} h</div><div class="font-medium text-sm text-gray-600 text-end">${taskData.payment_delay} Payment</br>${taskData.fte}% FTE</div></div>
                    `;

                    const hourlyRate = document.createElement('div');
                    hourlyRate.classList.add('ganttboard__task-dropdown-tile');
                    const currency = taskData.client_rate ? `${taskData.client_rate ?? "-"} ${taskData.client_currency ?? "PLN"}` : "-";
                    hourlyRate.innerHTML = `
                      <div class="ganttboard__tile-title">Hourly rate</div>
                      <div class="ganttboard__tile-hours">${taskData.hourly_rate} PLN (talent)</div>
                      <div class="ganttboard__tile-hours">${currency} (client)</div>
                    `;

                    const links = document.createElement('div');
                    links.classList.add('ganttboard__task-dropdown-container');
                    links.innerHTML = `
                      <div><a href="talent_orders/${taskData?.order_id}">${taskData.order_id ? "Order (talent)" : "missing link"}</a></div>
                      <div><a href="orders/${taskData?.client_order_id}">${taskData.client_order_id ? "Order (client)" : "missing link"}</a></div>
                    `;

                    taskDropdown.appendChild(currentViewing);
                    taskDropdown.appendChild(totalOrder);
                    taskDropdown.appendChild(hourlyRate);
                    taskDropdown.appendChild(links);
                    ganttChartContainer.appendChild(taskDropdown);
                }

            };

            indexRow++;
            this.ganttChartContainer.appendChild(taskElement);
        })
        return indexRow;
    }
    #calculatePosition(startDate) {
        let startDateObj = new Date(startDate);
        let daysDifference = Math.floor((startDateObj - this.start_date) / (1000 * 60 * 60 * 24));
        let position = daysDifference * this.DAY_WIDTH;
        return position + this.talentContainerWidth;
    }

    #calculateDuration(startDate, endDate) {
        let startDateObj = new Date(startDate).getTime();
        startDateObj = startDateObj < this.start_date ? this.start_date : startDateObj;

        let endDateObj = new Date(endDate);
        endDateObj.setDate(endDateObj.getDate()+1);
        endDateObj = endDateObj.getTime();
        endDateObj = endDateObj > this.end_date+1 ? this.end_date : endDateObj;

        let duration = Math.floor((endDateObj - startDateObj) / (1000 * 60 * 60 * 24));
        return duration * this.DAY_WIDTH;
    }

    #getTaskColor(status) {
        switch(status) {
            case "draft":
                return "none";
            default:
                return status;
        }
    }

    #filterTasks(filterData) {
        let talents = Array.from(this.tasks);

        if (filterData.start_date && filterData.end_date) {
            this.start_date = filterData.start_date;
            this.end_date = filterData.end_date;
            this.statuses = filterData.statuses;
            this.projects = filterData.projects;
            this.sources = filterData.sources;
            this.talents = filterData.talents;
            this.ftes = filterData.ftes;

            talents = talents.map(talent => {
                let filteredLineItems = talent.line_items.filter(li => {
                    let startDate = new Date(li.start_date).getTime();
                    let endDate = new Date(li.end_date).getTime();
                    return (startDate >= filterData.start_date.getTime() && endDate <= filterData.end_date.getTime()) ||
                        (startDate <= filterData.start_date.getTime() && endDate >= filterData.start_date.getTime()) ||
                        (startDate <= filterData.end_date.getTime() && endDate >= filterData.end_date.getTime());
                });

                if (filterData.talents.length > 0) {
                    if (!filterData.talents.includes(talent.talent.firstname + " " + talent.talent.lastname)) {
                        return {
                            ...talent,
                            line_items: []
                        }
                    }
                }
                if (filterData.sources.length > 0) {
                    if (!filterData.sources.includes(talent.talent_provider?.name)) {
                        return {
                            ...talent,
                            line_items: []
                        }
                    }
                }
                if (filterData.statuses.length > 0) {
                    filteredLineItems = filteredLineItems.filter(li => {
                        return filterData.statuses.includes(li.status);
                    });
                }
                if (filterData.projects.length > 0) {
                    filteredLineItems = filteredLineItems.filter(li => {
                        return filterData.projects.includes(li.project_name);
                    })
                }
                if (filterData.ftes.length > 0) {
                    filteredLineItems = filteredLineItems.filter(li => {
                        if (li.fte === undefined) {
                            return true;
                        }
                        const containsFte = filterData.ftes.map(fte => {
                            return fte.length > 1 ? (li.fte >= parseInt(fte[0]) && li.fte <= parseInt(fte[1])) : li.fte === parseInt(fte[0]);
                        });
                        return containsFte.includes(true);
                    });
                }

                return {
                    ...talent,
                    line_items: filteredLineItems
                }
            });
        }
        if (this.sort) {
            switch (this.sort) {
                case "name":
                    talents.sort((a, b) => {
                        if (a.talent.lastname < b.talent.lastname) { return -1; }
                        if (a.talent.lastname > b.talent.lastname) { return 1; }
                        return 0;
                    });
                    break;
                case "source":
                    talents.sort((a, b) => {
                        if (a.talent_provider.name < b.talent_provider.name) { return -1; }
                        if (a.talent_provider.name > b.talent_provider.name) { return 1; }
                        return 0;
                    });
                    break;
                case "status":
                    talents.sort((a, b) => {
                        if (a.talent.status < b.talent.status) { return -1; }
                        if (a.talent.status > b.talent.status) { return 1; }
                        return 0;
                    });
                    break;
            }
            if (this.sort_asc) {
                talents.reverse();
            }
        }
        return talents;
    }

    #getTaskArray(startDate, endDate) {
        let taskStartDate = new Date(startDate);
        let taskEndDate = new Date(endDate);

        let dates = [];

        while (taskStartDate.getTime() < taskEndDate.getTime()) {
            let newEndDate = new Date(taskStartDate.getFullYear(), taskStartDate.getMonth()+1, 0);
            if (newEndDate.getTime() > taskEndDate.getTime()) {
                dates.push({ start_date: taskStartDate, end_date: taskEndDate });
            } else {
                dates.push({ start_date: taskStartDate, end_date: newEndDate });
            }
            taskStartDate = new Date(taskStartDate.getFullYear(), taskStartDate.getMonth()+1, 1);
        }
        return dates;
    }
}

const gantt = new GanttChart();

document.addEventListener('DOMContentLoaded', () => {
    if (window.location.pathname === '/admin/ganttboard') {
        fetch('/admin/ganttboard/tasks', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
            }
        })
            .then(response => response.json())
            .then(tasks => {
                console.log(tasks)
                gantt.setData(tasks);
                gantt.render();
            })
            .catch(error => {
                console.error('Error fetching tasks:', error);
            });
        window.addEventListener('resize', () => {
           gantt.render({
               start_date: undefined,
               end_date: undefined,
               statuses: undefined,
               projects: undefined,
               talents: undefined,
               sources: undefined,
               ftes: undefined,
           });
        });

        const form = document.getElementById("form");
        form.addEventListener('submit', (event) => {
            event.preventDefault();

            const formData = new FormData(form);

            const date = formData.get('q[range_date]');
            const order_grouping = formData.get('q[order_grouping]').toLowerCase();
            const statuses =
                formData.getAll('q[filter_order_status][]')
                    .filter(status => status !== '')
                    .map(status => status.toLowerCase());
            const projects =
                formData.getAll('q[filter_order_projects][]')
                    .filter(project => project !== '');
            const talents =
                formData.getAll('q[filter_order_talents][]')
                    .filter(talent => talent !== '');
            const sources =
                formData.getAll('q[filter_order_sources][]')
                    .filter(source => source !== '');
            const ftes =
                formData.getAll('q[filter_order_fte][]')
                    .filter(fte => fte !== '')
                    .map(fte => fte.replaceAll("%", "").split(" - "));
            let sort = null;
            if (document.activeElement.value.length > 0) {
                sort = document.activeElement.value;
            }

            let filterObj = {};
            if (date) {
                let startDateParts = date.substring(0, date.indexOf("-")-1).split("/");
                let endDateParts = date.substring(date.indexOf("-")+2).split("/");
                filterObj.start_date = new Date(+startDateParts[2], startDateParts[1] - 1, +startDateParts[0]);
                filterObj.end_date = new Date(+endDateParts[2], endDateParts[1] - 1, +endDateParts[0]);
            }
            filterObj.statuses = statuses ?? null;
            filterObj.sort = sort ?? null;
            filterObj.order_grouping = order_grouping ?? null;

            if (event.submitter.value !== "clear") {
                filterObj.projects = projects ?? [];
                filterObj.talents = talents ?? [];
                filterObj.sources = sources ?? []
                filterObj.ftes = ftes ?? [];
            } else {
                filterObj.projects = [];
                filterObj.talents = [];
                filterObj.sources = [];
                filterObj.ftes = [];
            }

            console.log(filterObj.projects)

            gantt.render(filterObj);
        });
    }
});