import { CopyClientLink } from "./copy_client_link"

document.addEventListener('turbo:load', () => {
  if (document.querySelector(".show-get-link-js")) {
    let iframe;
    let button = document.querySelector(".show-get-link-js");
    let linkDiv = document.querySelector(".copy-get-link-js");

    button.addEventListener("click", () => {
      iframe = [].slice.call(document.querySelectorAll(".termsOfService__iframe"));
    
      const linkContent = [].slice.call(linkDiv.getElementsByTagName("p"));
      linkDiv.style.display = "block";

      CopyClientLink(linkContent[1].innerHTML);

      if (iframe && iframe.length > 0) {
          iframe.forEach(
            el => el.classList.add("termsOfService__iframe-disabled")
          )
      }

      window.addEventListener("click", (event) => {
        if (!event.target.classList.contains("copy-get-link-js") &&
            !event.target.classList.contains("show-get-link-js")) {
          linkDiv.style.display = "none";

          if (iframe && iframe.length > 0) {
            iframe.forEach(
              el => {
                if (el.classList.contains("termsOfService__iframe-disabled")) {
                  el.classList.remove("termsOfService__iframe-disabled");
                }
              }
            )
          }
        }
      })
    })
  }
})
