document.addEventListener('turbo:load', () => {
    let talentLineItemForm = document.querySelector('form.add-talent-line-item-form-js');
    if (!talentLineItemForm) {
        talentLineItemForm = document.querySelector('form.add-line-item-form-js');
    }

    if (talentLineItemForm) {
        talentLineItemForm.addEventListener('submit', (event) => {
            let startDate = document.getElementById('talent_order_talent_line_items_attributes_0_start_date');
            let endDate = document.getElementById('talent_order_talent_line_items_attributes_0_end_date');

            if (!startDate || !endDate) {
                startDate = document.getElementById('order_line_items_attributes_0_start_date');
                endDate = document.getElementById('order_line_items_attributes_0_end_date');
            }

            if (new Date(startDate.value) > new Date(endDate.value)) {
                event.preventDefault();
                const submitButton = document.querySelector('input.orderEdit__form-submit-button');
                submitButton.removeAttribute("data-disable-with");
                alert('Start date cannot be after the end date');
            }
        });
    }
});