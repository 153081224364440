import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="copy-link"
export default class extends Controller {
    static targets = [ "tooltip" ];
    static values = {
        link: String
    }

    connect(){}

    copyLink(){
        const linkToDiagram = this.linkValue
        navigator.clipboard.writeText(linkToDiagram)
        console.log(this.tooltipTarget)
        this.tooltipTarget.innerText = "Link copied"
    }

    hideMessage(){
        this.tooltipTarget.innerText = "Click to copy link"
    }
}
