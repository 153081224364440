import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropdown"
export default class extends Controller {
    static targets = [ "content" ]

    toggle(event) {
        let index = event.target.dataset.dropdown_id;
        if (!index) {
            return;
        }

        let clickedContent = this.contentTargets[index];
        let otherContent = this.contentTargets;
        otherContent.splice(index, 1);

        if (clickedContent) {
            clickedContent.classList.toggle("hidden");
            otherContent.forEach(content => {
                if (!content.classList.contains("hidden")) {
                    content.classList.add("hidden");
                }
            });
        }
    }

    toggleHighlight(event) {
        let buttons = [...event.target.parentElement.children];
        buttons.forEach((button) => {
            if(button.classList.contains("clicked")) {
                button.classList.remove("clicked");
            }
        });
        event.target.classList.add("clicked");
    }
}
