import { Controller } from "@hotwired/stimulus"
import { recalculate, formToDecimal } from '../time_entries_forms'

// Connects to data-controller="time-entries"
export default class extends Controller {
  static targets = [ "startTime", "endTime", "diffTime" ]

  connect() {
  }

  initialize() {
    this.startTime = this.startTimeTarget.value
    this.endTime = this.endTimeTarget.value
    this.diffTime = this.diffTimeTarget.value
  }

  toDecimal(input_value) {
    let valueFromInputWithoutColon = formToDecimal(input_value.target.value)
    input_value.target.value = valueFromInputWithoutColon
  }

  recalculate(){
    let startTime = recalculate(this.startTimeTarget.value)
    let endTime = recalculate(this.endTimeTarget.value)
    this.startTime = startTime
    this.endTime = endTime
    this.startTimeTarget.value = startTime
    this.endTimeTarget.value = endTime

    let startTimeVal = new Date("1970-01-01T" + startTime);
    let endTimeVal = new Date("1970-01-01T" + endTime);

    if(startTimeVal == "Invalid Date"){
      this.startTimeTarget.value = this.startTime;
      startTimeVal = new Date("1970-01-01T" + this.startTime);
    }
    if(endTimeVal == "Invalid Date"){
      this.endTimeTarget.value = this.endTime;
      endTimeVal = new Date("1970-01-01T" + this.endTime);
    }

    let timeDiff = (endTimeVal - startTimeVal)/(1000*60)
    if(timeDiff < 0){
      timeDiff = 24*60+timeDiff
    }

    let hours = Math.floor(timeDiff / 60).toString()
    let minutes = ('0'+(timeDiff % 60)).slice(-2)
    this.diffTimeTarget.value = hours+":"+minutes
  }

  adjust(){
    let parsedTime = /^(\d+):(\d{1,2})$/gm.exec(this.diffTimeTarget.value)
    if(parsedTime === null){
      this.diffTimeTarget.value = this.diffTime
    }
    else {
      let hours = parseInt(parsedTime[1])
      let minutes = parseInt(parsedTime[2])
      console.log(hours)
      console.log(minutes)
      let startTimeVal = new Date("1970-01-01T" + this.startTimeTarget.value);
      if(startTimeVal == "Invalid Date"){
        this.startTimeTarget.value = this.startTime;
        startTimeVal = new Date("1970-01-01T" + this.startTime);
      }
      let endTimeVal = new Date(startTimeVal.getTime() + (hours*60+minutes)*60*1000)
      hours = endTimeVal.getHours()
      minutes = ('0' + endTimeVal.getMinutes()).slice(-2)
      this.endTimeTarget.value = hours+":"+minutes
    }
  }
}
