import { Controller } from "@hotwired/stimulus"
import {get} from "@rails/request.js";

// Connects to data-controller="fill-talent-order"
export default class extends Controller {
    static targets = ["selectElement"]

    async updateForm(event) {
        const talentId = event.target.value;

        fetch(`/admin/talents/${talentId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
            }
        })
            .then(response => response.json())
            .then(talent => {
                const paymentDelay = document.getElementById('talent_order_talent_line_items_attributes_0_payment_delay');
                paymentDelay.value = talent.payment_delay;

                const providingServices = document.getElementById('talent_order_talent_line_items_attributes_0_providing_services');
                const options = Array.from(providingServices.options).filter(option => option.text === talent.providing_services);
                if (options.length > 0) {
                    providingServices.value = options[0].value;
                }
            })
            .catch(error => {
                console.error('Error fetching tasks:', error);
            });
    }
}