let adjustTalentFormFields = (form) => {
    if (form) {
        let talentSelect = form.querySelector(".talent-select-js");
        let regularTalentFieldsWrappers = form.querySelectorAll(".regular-talent-fields-wrapper-js");
        let regularTalentInputs = form.querySelectorAll(".regular-talent-input-js");
        let fteFieldInput = form.querySelector(".fte-field-js");

        function adjustFields() {
            if (!form.classList.contains("order-form-js")) {
                fteFieldInput.value = "";
            }
            regularTalentFieldsWrappers.forEach((field) => {
                field.style.display = "flex";
            })
            regularTalentInputs.forEach((input) => {
                input.value = "";
                input.required="required";
            })
        }

        adjustFields();

        talentSelect.addEventListener("change", adjustFields);
    }
}

document.addEventListener('turbo:load', () => {
    let addLineItemForm = document.querySelector(".add-talent-line-item-form-js")

    if (addLineItemForm) {
        adjustTalentFormFields(addLineItemForm)
    }

    let submitOnChangeSelects = document.querySelectorAll(".submit-on-change-js")
    if (submitOnChangeSelects) {
        submitOnChangeSelects.forEach((select) => {
            select.addEventListener('change', () => {
                document.querySelector(".submit-on-change-form-js").submit();
            });
        })
    }
})

window.adjustFormFields = adjustFormFields
