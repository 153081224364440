import { Controller } from "@hotwired/stimulus"
import { easepick } from "@easepick/bundle";
import { AmpPlugin } from '@easepick/amp-plugin';

// Connects to data-controller="pick-date-calendar"
export default class extends Controller {
    static targets = [ "dateInput" ];

    connect() {
        const picker = new easepick.create({
            element: this.dateInputTarget,
            css: [
                'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
            ],
            zIndex: 1,
            format: "DD/MM/YYYY",
            plugins: [AmpPlugin],
            AmpPlugin: {
                dropdown: {
                    months: true,
                    years: true,
                },
                darkMode: false,
            },
        });
    }
}
